<template>
    <article class="question" ref="card">
        <section class="text">
            {{question.text}}
        </section>
        <section class="scale">
            <div v-for="value in scale" :key="value" @click.prevent="selectedValue(value)">
                <a href="#">{{value}}</a>
            </div>
        </section>
    </article>
</template>

<script>
export default {
    name: 'question',
    props: ['question'],
    data(){
        return {
            scale: 5
        }
    },
    methods: {
        selectedValue(value){
            this.$emit('selectedValue', {value: value, intelligence: this.question.intelligence})
        }
    }
}
</script>

<style lang="scss">
    @import '../../../scss/variables';

    .question {
        width: 80vw;
        height: 120vw;
        background: white;
        display: flex;
        flex-direction: column;
        border-radius: $radius;
        will-change: transform;

        .text {
            flex: 4;
            padding: 2rem;
            @extend %center;
            font-weight: 700;
            font-size: 1.2rem;
            text-align: center;
        }

        .scale {
            flex: 1;
            @extend %center;
            padding: 0 1rem;
            justify-content: space-between;

            a {
                width: 2.4rem;
                height: 2.4rem;
                @extend %center;
                border-radius: 999rem;
                text-decoration: none;
                font-weight: 700;
                color: rgba($color: #000000, $alpha: 1.0);
                background: rgba($color: #000000, $alpha: .2);

                &:active {
                    background: rgba($color: #000000, $alpha: 1.0);
                    color: rgba($color: #FFFFFF, $alpha: 1.0);
                }
            }
        }
    }
</style>
