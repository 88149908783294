<template>
    <main id="questions">
        <aside class="back" @click="abort">avbryt</aside>
        <header class="instructions">
            {{ activeCard+1 }} / {{ questions.length }}
        </header>
        <question @selectedValue="handleValueChange"
            ref="qcard"
            :question="questions[activeCard]"
        />
    </main>
</template>

<script>

import question from './Question';
import anime from 'animejs';

export default {
    name: 'questions',
    components: {
        question
    },
    data(){
        return {
            activeCard: 0,
            duration:   300
        }
    },
    computed: {
        questions(){
            return this.$store.getters.gardner.questions;
        }
    },
    methods: {
        abort(){
            this.$emit('move', { transitionType: 'reveal', to: '/test/personlighetstestet' });
        },
        handleValueChange(value){
            
            if(this.activeCard+2 < this.questions.length || question == undefined ) {

                this.$store.commit('setGardnerResult', value);
                
                setTimeout(() => {
                    this.activeCard++;
                }, (this.duration/2))
                
                this.nextCard();

            } else {
                this.$router.push('/test/personlighetstestet/resultat');
            }
        },
        nextCard(){

            anime({
                targets: this.$refs.qcard.$el,
                easing: 'easeInOutQuad',
                translateX: [
                                { value: '-100vw', duration: this.duration/2 },
                                { value: '100vw', duration: 0       },
                                { value: 0, duration: this.duration }
                            ],
                opacity:    [
                                { value: 1, duration: this.duration/2 },
                                { value: 0, duration: 0             },
                                { value: 1, duration: this.duration }
                            ]
            })
        }
    }
}
</script>

<style lang="scss">
    @import '../../../scss/variables';

    #questions {
        @extend %center-column; 

        aside {
                position: absolute;
                top: 1rem;
                left: 1rem;
                padding: .25rem .5rem;
                border-radius: $radius;
                font-size: .8rem;
                border: 1px solid $lightgrey;
                color: $lightgrey;
        }
        
        .instructions {
            @extend %center;
            color: rgba($color: #FFF, $alpha: .4);
            font-weight: 700;
            margin: 1rem 0;
        }

        .choice {
            display: flex;
            width: 100%;
            padding: 0 2.4rem;

            section {
                flex: 1;
                @extend %center;
                color: white;
            }

        }
    }
</style>
